export const GET_SUBSCRIPTION_PLAN_SINGLE = "[SUBSCRIPTION_PLAN] Single Get";
export const GET_SUBSCRIPTION_PLAN_SINGLE_SUCCESS = "[SUBSCRIPTION_PLAN] Single Get Success";
export const GET_SUBSCRIPTION_PLAN_SINGLE_FAIL = "[SUBSCRIPTION_PLAN] Single Get Fail";

export const CREATE_SUBSCRIPTION_PLAN = "[SUBSCRIPTION_PLAN] Create";
export const CREATE_SUBSCRIPTION_PLAN_SUCCESS = "[SUBSCRIPTION_PLAN] Create Success";
export const CREATE_SUBSCRIPTION_PLAN_FAIL = "[SUBSCRIPTION_PLAN] Create Fail";
export const CREATE_SUBSCRIPTION_PLAN_RESET = "[SUBSCRIPTION_PLAN] Create Reset";

export const UPDATE_SUBSCRIPTION_PLAN = "[SUBSCRIPTION_PLAN] Update";
export const UPDATE_SUBSCRIPTION_PLAN_SUCCESS = "[SUBSCRIPTION_PLAN] Update Success";
export const UPDATE_SUBSCRIPTION_PLAN_FAIL = "[SUBSCRIPTION_PLAN] Update Fail";
export const UPDATE_SUBSCRIPTION_PLAN_RESET = "[SUBSCRIPTION_PLAN] Update Reset";

export const REPLACE_SUBSCRIPTION_PLAN = "[SUBSCRIPTION_PLAN] Replace";
export const REPLACE_SUBSCRIPTION_PLAN_SUCCESS = "[SUBSCRIPTION_PLAN] Replace Success";
export const REPLACE_SUBSCRIPTION_PLAN_FAIL = "[SUBSCRIPTION_PLAN] Replace Fail";
export const REPLACE_SUBSCRIPTION_PLAN_RESET = "[SUBSCRIPTION_PLAN] Replace Reset";

export const REMOVE_SUBSCRIPTION_PLAN = "[SUBSCRIPTION_PLAN] Remove";
export const REMOVE_SUBSCRIPTION_PLAN_SUCCESS = "[SUBSCRIPTION_PLAN] Remove Success";
export const REMOVE_SUBSCRIPTION_PLAN_FAIL = "[SUBSCRIPTION_PLAN] Remove Fail";

export const GET_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD = "[SUBSCRIPTION_PLAN] Stripe Payment Method Get";
export const GET_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD_SUCCESS = "[SUBSCRIPTION_PLAN] Stripe Payment Method Get Success";
export const GET_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD_FAIL = "[SUBSCRIPTION_PLAN] Stripe Payment Method Get Fail";

export const DETACH_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD = "[SUBSCRIPTION_PLAN] Stripe Payment Method Cancel";
export const DETACH_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD_SUCCESS = "[SUBSCRIPTION_PLAN] Stripe Payment Method Cancel Success";
export const DETACH_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD_FAIL = "[SUBSCRIPTION_PLAN] Stripe Payment Method Cancel Fail";

export const CREATE_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT = "[SUBSCRIPTION_PLAN] Stripe Setup Intent Create";
export const CREATE_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_SUCCESS = "[SUBSCRIPTION_PLAN] Stripe Setup Intent Create Success";
export const CREATE_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_FAIL = "[SUBSCRIPTION_PLAN] Stripe Setup Intent Create Fail";

export const CONFIRM_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT = "[SUBSCRIPTION_PLAN] Stripe Setup Intent Confirm";
export const CONFIRM_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_SUCCESS = "[SUBSCRIPTION_PLAN] Stripe Setup Intent Confirm Success";
export const CONFIRM_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_FAIL = "[SUBSCRIPTION_PLAN] Stripe Setup Intent Confirm Fail";

export const CANCEL_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT = "[SUBSCRIPTION_PLAN] Stripe Setup Intent Cancel";
export const CANCEL_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_SUCCESS = "[SUBSCRIPTION_PLAN] Stripe Setup Intent Cancel Success";
export const CANCEL_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_FAIL = "[SUBSCRIPTION_PLAN] Stripe Setup Intent Cancel Fail";

export const SEND_CONTRACT = "[SUBSCRIPTION_PLAN] Send Contract";
export const SEND_CONTRACT_SUCCESS = "[SUBSCRIPTION_PLAN] Send Contract Success";
export const SEND_CONTRACT_FAIL = "[SUBSCRIPTION_PLAN] Send Contract Fail";

export const GET_CONTRACT = "[SUBSCRIPTION_PLAN] Get Contract";
export const GET_CONTRACT_SUCCESS = "[SUBSCRIPTION_PLAN] Get Contract Success";
export const GET_CONTRACT_FAIL = "[SUBSCRIPTION_PLAN] Get Contract Fail";

export const SYNC_CHARGE_BEE = "[SUBSCRIPTION_PLAN] Chargebee Sync";
export const SYNC_CHARGE_BEE_SUCCESS = "[SUBSCRIPTION_PLAN] Chargebee Sync Success";
export const SYNC_CHARGE_BEE_FAIL = "[SUBSCRIPTION_PLAN] Chargebee Sync Fail";