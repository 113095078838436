import {
  LOAD_CONSTANTS,
  LOAD_TRANSLATIONS
} from "../app/actions";

import {
  GET_AUTH_USER
} from "../auth/actions";

import Monitor from "@/services/monitor";
import WorkerService from "@/services/worker";

export const authenticate = (context, config) => {
  config = config || {};  

  return new Promise(resolve => {
    context.dispatch(GET_AUTH_USER).then((response) => {
      context.dispatch(LOAD_CONSTANTS);
      const modules = config.translationModules;

      try {
        const optOutRoles = [
          'INTERNAL_SUPPORT',
          'INTERNAL_SALES',
          'INTERNAL_ACCOUNTING',
          'INTERNAL_ONBOARDING',
          'ADMIN',
        ];

        const mustTrack = process.env.VUE_APP_MIXPANEL_DEBUG;
        const userRoles = Object.keys(response.data.roles).filter(key => response.data.roles[key]); ;
        const optOut = optOutRoles.some(role => userRoles.includes(role));
        const isSubscribedNotificatons = !!WorkerService.isSubscribed();

        if (optOut && !mustTrack) {
          Monitor.optOut();
        } else {
          Monitor.optIn();
          Monitor.identify(response.data.id);
          Monitor.setProfileProperties({
            '$name': response.data.name,
            '$email': response.data.email,
            'version': `${APP_VERSION}`,
            roles: userRoles,
            has_webpush_notifications: isSubscribedNotificatons,
          });
        }
      } catch(e) {
        console.error(e);
      }

      context.dispatch(LOAD_TRANSLATIONS, { modules }).then(() => {
        resolve(true);
      });
    });
  });
};

